import {
  Container,
  InputAdornment,
  IconButton,
  TextField,
  Button,
  Link,
  Collapse,
  Alert,
  Box,
  useMediaQuery,
} from "@mui/material";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect, useState } from "react";
import logo from "../images/logoWeekend.png";
import axios from "axios";
import { styled, useTheme } from "@mui/material/styles";

const GrTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(198, 186, 171, 1)",
      borderRadius: 15,
      borderWidth: 2,
    },
    "&:hover fieldset": {
      borderColor: "rgba(158, 158, 116, 1)",
      borderRadius: 15,
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(198, 186, 171, 1)",
      borderRadius: 15,
    },
    "& input": {
      backgroundColor: "rgba(255, 253, 251, 1)",
      borderRadius: 15,
      margin: 7,
      fontSize: 18,
    },
  },
  "& label": {
    transform: "translate(50%, 100%) scale(1)",
    "&.MuiInputLabel-shrink": {
      transform: "translate(30%, -50%) scale(0.75)",
      color: "white",
    },
  },
});

export const AuthForm = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (email && password) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [email, password]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async () => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append("username", email);
      bodyFormData.append("password", password);
      await axios.post("/api/auth/jwt/login", bodyFormData);
    } catch {
      setOpen(true);
    }
  };

  return (
    <Box
      sx={{
        height: isMobile ? "90vh" : "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: isMobile ? "center" : "flex-start",
        flexGrow: 1,
      }}
    >
      <Container
        sx={{
          alignSelf: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: { xs: "80%", sm: "75%", md: "35%" },
          minWidth: { xs: "250px", sm: "350px", md: "450px" },
          padding: { xs: 3, sm: 5, md: 8 },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <img
            src={logo}
            alt="Лого викенда"
            style={{
              maxWidth: "110px",
              height: "auto",
              alignSelf: "center",
            }}
          />

          <GrTextField
            label="Логин"
            fullWidth
            sx={{ mt: 5 }}
            onChange={(e) => setEmail(e.target.value)}
          />

          <GrTextField
            label="Пароль"
            fullWidth
            type={showPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <VisibilityOff sx={{ color: "rgba(255, 253, 251, 1)" }} />
                    ) : (
                      <Visibility sx={{ color: "rgba(255, 253, 251, 1)" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ mt: 2 }}
          />
          <Collapse in={open}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{
                mt: 2,
                borderRadius: "15px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Пользователя с такими данными не существует! <br />
            </Alert>
          </Collapse>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            sx={{
              mt: 7,
              mb: 2,
              padding: 1.5,
              fontSize: 18,
              backgroundColor: "rgba(38, 121, 137, 1)",
              textTransform: "capitalize",
              borderRadius: "15px",
            }}
            disabled={!isActive}
            onClick={handleLogin}
          >
            Вход
          </Button>
          <Link
            color="secondary"
            href="/registration"
            underline="none"
            sx={{
              color: "rgba(42, 83, 81, 1)",
              fontSize: 20,
              textAlign: "center",
            }}
          >
            {" "}
            <b> Регистрация</b>
          </Link>
        </Box>
      </Container>{" "}
    </Box>
  );
};
