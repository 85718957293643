import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import vk from "../images/vk.png";
import tg from "../images/tg.png";
import { Logout } from "@mui/icons-material";
import axios from "axios";

const linkStyle = {
  color: "black",
  textDecoration: "none",
};

export default function Header(props) {
  return (
    <Box
      sx={{
        display: "flex",
        textAlign: "center",
        p: 2,
        backgroundColor: "rgba(255, 255, 255, 0.53)",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        boxSizing: "border-box",
        gap: 7,
      }}
    >
      <Box display="flex" gap={3}>
        <Link to={"https://t.me/itcubenorilsk"} style={linkStyle}>
          <img
            src={tg}
            alt="tg"
            style={{
              maxHeight: "30px",
              height: "auto",
              alignSelf: "center",
            }}
          />
        </Link>
        <Link to={"https://vk.com/itcube.norilsk"} style={linkStyle}>
          <img
            src={vk}
            alt="vk"
            style={{
              maxHeight: "30px",
              height: "auto",
              alignSelf: "center",
            }}
          />
        </Link>
      </Box>
      <Typography
        sx={{
          fontSize: 30,
          fontWeight: "bold",
          color: "rgba(38, 121, 137, 1)",
        }}
      >
        СКРИЖАЛИ ЛАГОВ
      </Typography>
      {/*{props.isAuth ? (
        <Button
          onClick={async () => {
            try {
              await axios.post("/api/auth/jwt/logout");
            } catch {
              alert("Какие-то ошибки с logout");
            }
          }}
          sx={{
            color: "rgba(38, 121, 137, 1)",
          }}
        >
          <Logout sx={{ fontSize: "36px" }} />
        </Button>
      ) : (
        <></>
      )}*/}
    </Box>
  );
}
