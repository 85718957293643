export const firstPartOfRegistTextFields = [
  {
    label: "ФИО",
    name: "name",
  },
  {
    label: "Почта",
    name: "email",
  },
];
