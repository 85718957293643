import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { tasksData } from "../static/tasks";
import Svitok from "./tasks/svitok";
import RightPath from "./rightPath";
import { NavigationPanel } from "./navigation/navPanel";
import { NavigationDrawer } from "./navigation/navDrawer";
import { Dehaze } from "@mui/icons-material";
import axios from "axios";

export default function MainComponent(props) {
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setDrawerOpen(false);
  };

  const navigationHandlers = {
    next: () => {
      setValue((prevValue) => (prevValue + 1) % tasksData.length);
    },
    back: () => {
      setValue(
        (prevValue) => (prevValue - 1 + tasksData.length) % tasksData.length
      );
    },
  };

  const theme = createTheme({
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "white",
          },
        },
      },
    },
  });

  const [data, setData] = React.useState([
    { nickname: "nickname", email: "email", role: "role" },
  ]);

  const dataRefresh = async () => {
    try {
      if (props.isAuth === true) {
        const res = await axios.get("/api/user_info");
        setData({
          nickname: res.data.nickname,
          email: res.data.email,
          role: res.data.role,
        });
        const mode = await axios.post("/api/hard_mode_check");
        props.setIsHard(mode.data);
      }
    } catch {
      setData([{ nickname: "nickname", email: "email", role: "role" }]);
    }
  };
  useEffect(() => {
    dataRefresh();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <Button
        sx={{
          display: { xs: "block", sm: "none" },
          position: "fixed",
          top: 10,
          right: 20,
          zIndex: 3,
          color: "white",
          textTransform: "uppercase",
        }}
        onClick={() => setDrawerOpen(true)}
      >
        <Dehaze sx={{ fontSize: "36px", color: "rgba(73, 131, 123, 1)" }} />
      </Button>
      <ThemeProvider theme={theme}>
        <NavigationDrawer
          isOpen={isDrawerOpen}
          onClose={() => setDrawerOpen(false)}
          tasksData={tasksData}
          value={value}
          handleChange={handleChange}
          isHard={props.isHard}
          data={data}
        />

        <NavigationPanel
          tasksData={tasksData}
          value={value}
          handleChange={handleChange}
          isHard={props.isHard}
          data={data}
        />
      </ThemeProvider>
      {tasksData.map((task, idx) => (
        <Box
          key={idx}
          role="tabpanel"
          hidden={value !== idx}
          id={`tabpanel-${idx}`}
          aria-labelledby={`tab-${idx}`}
          sx={{ width: "100%" }}
        >
          <Svitok
            setData={setData}
            flagText={task.taskTitle}
            chtoRisyem={task.taskType}
            zagolovok={task.taskText}
            navigationHandlers={navigationHandlers}
            taskId={task.cid}
            isHard={props.isHard}
            setIsHard={props.setIsHard}
            data={data}
            userRole={data.role}
          />
        </Box>
      ))}

      <RightPath authData={props.authData} isAuth={props.isAuth} />
    </Box>
  );
}
