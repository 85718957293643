import { Alert, Collapse, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

export const Send = (props) => {
  // Установим сообщение по умолчанию, если modalData не задано
  const alertMessage = props.modalData || "Произошла ошибка. Попробуйте снова.";

  return (
    <Collapse in={props.modalOpen}>
      <Alert
        severity={
          props.modalData ===
          "Серверные шоколадки, попробуйте еще раз отправить ответ"
            ? "error"
            : "success"
        }
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              props.setModalOpen(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        sx={{ mt: 2, borderRadius: "15px" }}
      >
        {alertMessage}
      </Alert>
    </Collapse>
  );
};
