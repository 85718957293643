import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Send } from "../information/send";
import { Answer } from "../information/answer";
import { Box } from "@mui/material";

export default function FewAnswers(props) {
  const [answers, setAnswers] = React.useState(
    props.ans.map(() => 0) // Изначально все чекбоксы отмечены
  );

  const handleChange = (index) => {
    setAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[index] = newAnswers[index] === 1 ? 0 : 1; // Переключаем 0 и 1
      const resultString = newAnswers.join("");
      props.onAnsChange(resultString);
      return newAnswers;
    });
  };

  return (
    <Box>
      <FormGroup
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" }, // Две колонки одинаковой ширины
          gap: 2, // Расстояние между элементами
          justifyItems: "stretch",
          justifyContent: "space-between",
          mb: 4,
          ml: { xs: 2, sm: 0 },
        }}
      >
        {props.ans.map((value, index) => (
          <FormControlLabel
            key={index}
            sx={{ textAlign: "start", alignItems: "flex-start" }}
            control={
              <Checkbox
                sx={{
                  color: "rgba(38, 121, 137, 1)",
                  "&.Mui-checked": {
                    color: "rgba(38, 121, 137, 1)", // Цвет галочки, если выбрана
                  },
                }}
                checked={answers[index] === 1}
                onChange={() => handleChange(index)}
              />
            }
            label={
              <span
                style={{
                  fontSize: "20px",
                  color: "rgba(27, 55, 54, 1)", // Цвет текста розовый
                }}
              >
                {value}
              </span>
            }
          />
        ))}
      </FormGroup>
      <Send
        modalData={props.modalData}
        modalOpen={props.modalOpen}
        setModalOpen={props.setModalOpen}
      />
      <Answer isAnswerOpen={props.isAnswerOpen} answerData={props.answerData} />
    </Box>
  );
}
