import { OneOf } from "../components/tasks/oneOf";
import { Sopostav } from "../components/tasks/sopostav";
import { InputByUser } from "../components/tasks/inputByUserWithImg";
import FewAnswers from "../components/tasks/fewAnswers";
import { Results } from "../components/information/results";
import n1 from "../images/normal/n1.jpg";
import n2 from "../images/normal/n2.jpg";
import n3 from "../images/normal/n3.jpg";
import n4 from "../images/normal/n4.jpg";
import n5 from "../images/normal/n5.jpg";
import n6 from "../images/normal/n6.jpg";
import n7 from "../images/normal/n7.jpg";
import n8 from "../images/normal/n8.jpg";
import n9 from "../images/normal/n9.jpg";
import n10 from "../images/normal/n10.jpg";
import { HardMain } from "../components/information/hardMain";
import { NormalMain } from "../components/information/normalMain";
import h1 from "../images/hard/h1.jpg";
import h2 from "../images/hard/h2.jpg";
import h3 from "../images/hard/h3.jpg";
import h4 from "../images/hard/h4.jpg";
import h5 from "../images/hard/h5.jpg";
import h6 from "../images/hard/h6.jpg";
import h7 from "../images/hard/h7.jpg";
import h8 from "../images/hard/h8.jpg";
import fog from "../images/fog.png";
import Campbell from "../images/Campbell.jpg";
import Longing from "../images/Longing.jpg";
import trikster from "../images/Trikster.jpg";
import sims from "../images/sims.jpg";
import dLight from "../images/dLight.jpeg";

export const tasksData = [
  {
    id: 0,
    cid: 0,
    taskTitle: "Главная",
    taskText: "Добро пожаловать на квест приключений!",
    taskType: <NormalMain />,
  },
  {
    id: 1,
    cid: 1,
    taskTitle: "Вопрос 1",
    taskText: "Какие языки программирования поддерживает unreal engine? ",
    taskType: (
      <FewAnswers ans={["C++", "C#", "BluePtint", "JavaScript", "C"]} id={1} />
    ),
  },
  {
    id: 2,
    cid: 2,
    taskTitle: "Вопрос 2",
    taskText: "Для чего используют UV-развертку? ",
    taskType: (
      <OneOf
        ans={[
          "Для наложения текстуры на 3D-модель",
          "Для создания анимации",
          "Для настройки света",
          "Для оптимизации кода",
        ]}
        id={2}
      />
    ),
  },
  {
    id: 3,
    cid: 3,
    taskTitle: "Вопрос 3",
    taskText: "Что такое 'игровой нарратив'?",
    taskType: (
      <OneOf
        ans={[
          "Сюжет игры, который обязательно включает в себя диалоги",
          "История, которая создается в ответ на действия игрока и события в игровом процессе",
          "Чисто визуальное повествование без текста",
          "Легенда или мифология, на которой основана игра",
        ]}
        id={3}
      />
    ),
  },
  {
    id: 4,
    cid: 4,
    taskTitle: "Вопрос 4",
    taskText:
      "На какой игровой консоли вышла легендарная игра The Legend of Zelda: Ocarina of Time? ",
    taskType: (
      <OneOf
        ans={["PlayStation", "Sega Saturn", "Game Boy Advance", "Nintendo 64"]}
        id={4}
      />
    ),
  },
  {
    id: 5,
    cid: 5,
    taskTitle: "Вопрос 5",
    taskText:
      "Какой элемент геймдизайна определяет уровень сложности для игроков?",
    taskType: (
      <OneOf
        ans={["Сюжет", "Баланс", "Атмосфера", "Количество механик"]}
        id={5}
      />
    ),
  },
  {
    id: 6,
    cid: 6,
    taskTitle: "Вопрос 6",
    taskText:
      "Почему ошибки прозвали 'багами'?",
    taskType: (
      <OneOf
        ans={[
          "Из-за мотылька застрявшего в устройстве",
          "Из-за вредоносного характера",
          "В честь разработчика коллекционирующего жуков ",
          "Из-за сложности в поимке",
        ]}
        id={6}
      />
    ),
  },
  {
    id: 7,
    cid: 7,
    taskTitle: "Вопрос 7",
    taskText: "Что такое hitbox?",
    taskType: (
      <OneOf
        ans={[
          "Музыкальная дорожка с эффектами",
          "Зона респауна игрока",
          "Пространство определяющее размер и положение объектов",
          "Панель интерфейса управления",
        ]}
        id={7}
      />
    ),
  },
  {
    id: 8,
    cid: 8,
    taskTitle: "Вопрос 8",
    taskText: "Какой игровой-кор лежит в основе игры «Тетрис»?",
    taskType: (
      <OneOf
        ans={[
          "Приземли фигуру — дождись следующей фигуры.",
          "Дождись появления фигуры — ускорь приземление фигуры — получи очки.",
          "Приземли фигуру — создай линию без пробелов — получи очки.",
          "Приземли фигуру - заполни экран - проиграй.",
        ]}
        id={8}
      />
    ),
  },
  {
    id: 9,
    cid: 9,
    taskTitle: "Вопрос 9",
    taskText: "Что такое текстура? ",
    taskType: (
      <OneOf
        ans={[
          "Материал, из которого сделана 3D-модель",
          "Световой эффект",
          "Картинка, нанесенная на поверхность 3D-объекта",
          "Анимация движения",
        ]}
        id={9}
      />
    ),
  },
  {
    id: 10,
    cid: 10,
    taskTitle: "Вопрос 10",
    taskText: "Что такое 'интерактивное повествование'?",
    taskType: (
      <OneOf
        ans={[
          "Сюжет, который не изменяется в зависимости от действий игрока",
          "Повествование, в котором игрок может влиять на развитие сюжета через свои выборы",
          "История, которая только отображает результаты действий персонажа",
          "История, которая не имеет начала или конца",
        ]}
        id={10}
      />
    ),
  },
  {
    id: 11,
    cid: 11,
    taskTitle: "Вопрос 11",
    taskText: "Какие из этих игр созданы Российскими гейм-студиями?",
    taskType: (
      <FewAnswers
        ans={[
          "Pathfinder: Wrath of the Righteous",
          "Divinity: Original Sin 2",
          "Heroes of Might and Magic V",
          "Might & Magic Heroes VII",
          "Age of Wonders 4",
          "Age of Pirates: Caribbean Tales",
          "Тетрис",
          "Все эти игры созданы Российскими студиями",
        ]}
        id={11}
      />
    ),
  },
  {
    id: 12,
    cid: 12,
    taskTitle: "Вопрос 12",
    taskText: "Что такое баг в контексте тестирования видеоигр??",
    taskType: (
      <OneOf
        ans={[
          "Ошибка в коде, которая не влияет на игровой процесс",
          "Неправильная или неожиданная работа игры, нарушающая её функциональность",
          "Графическая ошибка, которая не мешает геймплею",
          "Реклама внутри игры",
        ]}
        id={12}
      />
    ),
  },
  {
    id: 13,
    cid: 13,
    taskTitle: "Вопрос 13",
    taskText: "Что означает термин frame rate?",
    taskType: (
      <OneOf
        ans={[
          "Размер текстур в игре",
          "Количество уровней в игре",
          "Плавность загрузки сцен",
          "Количество кадров в секунду",
        ]}
        id={13}
      />
    ),
  },
  {
    id: 14,
    cid: 14,
    taskTitle: "Вопрос 14",
    taskText: "Из списка ниже выберите всё, что является игровыми механиками",
    taskType: (
      <FewAnswers
        ans={[
          "Покраска скина в разные цвета.",
          "Картинка персонажа.",
          "Программный код передвижения персонажа.",
          "Выбор действия игроком.",
          "Прокачка статов персонажа.",
          "Все в этом списке",
        ]}
        id={14}
      />
    ),
  },
  {
    id: 15,
    cid: 15,
    taskTitle: "Вопрос 15",
    taskText:
      "Какие элементы могут составлять основу наративного дизайна в видеоигре?",
    taskType: (
      <OneOf
        ans={[
          "Персонажи, диалоги, мир и события",
          "Только сюжетные катсцены",
          "Физика движения персонажа",
          "Только текстовые квесты",
        ]}
        id={15}
      />
    ),
  },
  {
    id: 16,
    cid: 16,
    taskTitle: "Вопрос 16",
    taskText: "Что такое спрайт в контексте 2D-игр?  ",
    taskType: (
      <OneOf
        ans={[
          "Вид оружия",
          "Название уровня",
          "Изображение персонажа или объекта",
          "Эффект освещения",
        ]}
        id={16}
      />
    ),
  },
  {
    id: 17,
    cid: 17,
    taskTitle: "Вопрос 17",
    taskText: "Что такое стресс-тестирование в контексте тестирования видеоигр",
    taskType: (
      <OneOf
        ans={[
          "Проверка игры на наличие багов при слабой аппаратуре",
          "Проверка игры при экстремальных нагрузках",
          "Проверка пользовательского интерфейса",
          "Проверка графической составляющей игры",
        ]}
        id={17}
      />
    ),
  },
  {
    id: 18,
    cid: 18,
    taskTitle: "Вопрос 18",
    taskText: "Какой покемон имеет порядковый номер 001?",
    taskType: (
      <OneOf
        ans={["Пикачу", "Чармандер", "Бульбазавр", "Сквиртл", "Псайдак"]}
        id={18}
      />
    ),
  },
  {
    id: 19,
    cid: 19,
    taskTitle: "Вопрос 19",
    taskText: "Через что игрок взаимодействует с игровыми объектами?",
    taskType: (
      <OneOf
        ans={[
          "Через игровые механики",
          "Через управление",
          "Через интерфейс пользователя",
          "Через экран монитора",
        ]}
        id={19}
      />
    ),
  },
  {
    id: 20,
    cid: 20,
    taskTitle: "Вопрос 20",
    taskText: "Что такое 'collision detection' в контексте видеоигр?",
    taskType: (
      <OneOf
        ans={[
          "Детекция точек столкновения между игровыми объектами",
          "Проверка того, как быстро работает игра",
          "Метод проверки игровых уровней на наличие ошибок",
          "Оценка качества текстур на объектах игры",
        ]}
        id={20}
      />
    ),
  },
  {
    id: 21,
    cid: 21,
    taskTitle: "Вопрос 21",
    taskText:
      "Какой тип тестирования используется для проверки взаимодействия разных частей игры, например, между механиками и интерфейсами?",
    taskType: (
      <OneOf
        ans={[
          "Тестирование совместимости",
          "Интеграционное тестирование",
          "Модульное тестирование",
          "Тестирование регрессии",
        ]}
        id={21}
      />
    ),
  },
  {
    id: 22,
    cid: 22,
    taskTitle: "Вопрос 22",
    taskText: "Что такое полигоны?",
    taskType: (
      <OneOf
        ans={[
          "Световые эффекты",
          "Настройки камеры",
          "Треугольники, из которых строятся 3D-объекты",
          "Элементы управления",
        ]}
        id={22}
      />
    ),
  },
  {
    id: 23,
    cid: 23,
    taskTitle: "Вопрос 23",
    taskText: "Что отличает игры жанра roguelike от roguelite?",
    taskType: (
      <OneOf
        ans={[
          "Наличие открытого мира",
          "Полная утрата прогресса после смерти",
          "Использование 2D-графики",
          "Фокус на многопользовательском режиме",
        ]}
        id={23}
      />
    ),
  },
  {
    id: 24,
    cid: 24,
    taskTitle: "Вопрос 24",
    taskText:
      "Какая структура повествования чаще всего используется в играх с открытым миром?",
    taskType: (
      <OneOf
        ans={[
          "Линейное повествование",
          "Ветвящееся повествование с множеством вариантов развития",
          "Сложное и многослойное повествование с дополнительными миссиями",
          "Повествование без четкой структуры",
        ]}
        id={24}
      />
    ),
  },
  {
    id: 25,
    cid: 25,
    taskTitle: "Вопрос 25",
    taskText: "Какую функцию выполняет game designer в разработке игры?",
    taskType: (
      <OneOf
        ans={[
          "Программирует игровые функции",
          "Создает 3D-модели",
          "Рисует текстуры",
          "Разрабатывает концепцию и механику игры",
        ]}
        id={25}
      />
    ),
  },
  {
    id: 26,
    cid: 26,
    taskTitle: "Вопрос 26",
    taskText: "Сопоставьте вселенную и создателя",
    taskType: (
      <Sopostav
        ans={[
          "Тодд Говард",
          "Хидетака Миядзаки",
          "Кен Леви",
          "Синдзи Миками",
          "Хидео Кодзима",
          "Хаяо Миядзаки ",
        ]}
        variants={[
          "Bioshock",
          "Death Stranding",
          "Skyrim",
          "Dark Souls",
          "Resident Evil",
          "Biohazard",
        ]}
        id={26}
      />
    ),
  },
  {
    id: 27,
    cid: 27,
    taskTitle: "Вопрос 27",
    taskText:
      "Как называется ошибка, когда новая версия игры ломает функциональность, которая работала в предыдущей версии?",
    taskType: (
      <OneOf
        ans={[
          "Обратная ошибка",
          "Черный ящик",
          "Регрессионная ошибка",
          "Системная ошибка",
        ]}
        id={27}
      />
    ),
  },
  {
    id: 28,
    cid: 28,
    taskTitle: "Вопрос 28",
    taskText:
      "Как называется техника, при которой на объект накладываются разные карты (нормалей, отражений)?",
    taskType: (
      <OneOf ans={["Анимация", "Шейдинг", "Риггинг", "Скриптинг"]} id={28} />
    ),
  },
  {
    id: 29,
    cid: 29,
    taskTitle: "Вопрос 29",
    taskText: "В каком году была выпущена первая версия Unity?",
    taskType: <OneOf ans={["2003", "2005", "2007", "2010"]} id={29} />,
  },
  {
    id: 30,
    cid: 30,
    taskTitle: "Вопрос 30",
    taskText:
      "Как наративный дизайн может способствовать созданию атмосферности в видеоигре?",
    taskType: (
      <OneOf
        ans={[
          "Путем использования сложных алгоритмов искусственного интеллекта",
          "За счет продуманной интеграции сюжета с окружающим миром и атмосферой",
          "Через исключительно текстовые миссии",
          "Используя постоянное изменение механик игры",
        ]}
        id={30}
      />
    ),
  },
  {
    id: 31,
    cid: 31,
    taskTitle: "Вопрос 31",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={n1} id={31} />,
  },
  {
    id: 32,
    cid: 32,
    taskTitle: "Вопрос 32",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={n2} id={32} />,
  },
  {
    id: 33,
    cid: 33,
    taskTitle: "Вопрос 33",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={n3} id={33} />,
  },
  {
    id: 34,
    cid: 34,
    taskTitle: "Вопрос 34",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={n4} id={34} />,
  },
  {
    id: 35,
    cid: 35,
    taskTitle: "Вопрос 35",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={n5} id={35} />,
  },
  {
    id: 36,
    cid: 36,
    taskTitle: "Вопрос 36",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={n6} id={36} />,
  },
  {
    id: 37,
    cid: 37,
    taskTitle: "Вопрос 37",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={n7} id={37} />,
  },
  {
    id: 38,
    cid: 38,
    taskTitle: "Вопрос 38",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={n8} id={38} />,
  },
  {
    id: 39,
    cid: 39,
    taskTitle: "Вопрос 39",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={n9} id={39} />,
  },
  {
    id: 40,
    cid: 40,
    taskTitle: "Вопрос 40",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={n10} id={40} />,
  },
  {
    id: 41,
    cid: 100,
    taskTitle: "Итоги",
    taskText: "спасибо за участие в нашем квесте",
    taskType: <Results id={100} />,
  },
  {
    id: 42,
    cid: 100,
    taskTitle: "Хард",
    taskText: "Добро пожаловать в хард мод!",
    taskType: <HardMain id={100} />,
  },
  {
    id: 43,
    cid: 41,
    taskTitle: "Вопрос 1",
    taskText: "Что такое игровой процесс?",
    taskType: (
      <OneOf
        ans={[
          "Комплекс взаимодействий игрока посредством набора игровых механик в рамках ограничений игры, формирующий особый опыт.",
          "Система оценки действий игрока в игре в рамках установленных правил.",
          "Набор механических действий игрока.",
          "Процесс участия игрока в игре.",
        ]}
        id={29}
      />
    ),
  },
  {
    id: 44,
    cid: 42,
    taskTitle: "Вопрос 2",
    taskText: "В Какой игре из-за ограничений движка появился туман?",
    taskType: <InputByUser img={fog} g={0} id={44} />,
  },
  {
    id: 45,
    cid: 43,
    taskTitle: "Вопрос 3",
    taskText:
      "Как называется процесс, когда 3D-модель становится 'скелетом' для анимации?  ",
    taskType: (
      <OneOf
        ans={["Риггинг", "Текстурирование", "Рендеринг", "Редактирование"]}
        id={45}
      />
    ),
  },
  {
    id: 46,
    cid: 44,
    taskTitle: "Вопрос 4",
    taskText: "На каком языке написана игра?",
    taskType: (
      <Sopostav
        ans={["C#", "C++", "Python"]}
        variants={[
          "Holow knigght",
          "Witсher 3",
          "The Elder Scrolls V: Skyrim",
          "Civilization IV",
          "Grand Theft Auto V",
          "Cities: Skylines",
          "Sims 4",
        ]}
        id={46}
      />
    ),
  },
  {
    id: 47,
    cid: 45,
    taskTitle: "Вопрос 5",
    taskText:
      "Какие из следующих типов структур являются наиболее распространёнными в нарративном дизайне видеоигр?",
    taskType: (
      <OneOf
        ans={[
          "Линейная структура",
          "Ветвящаяся структура",
          "Открытый мир",
          "Все вышеперечисленные",
        ]}
        id={47}
      />
    ),
  },
  {
    id: 48,
    cid: 46,
    taskTitle: "Вопрос 6",
    taskText:
      "Какая из следующих игр является первым проектом студии FromSoftware?",
    taskType: (
      <OneOf
        ans={["Demon's Souls", "Dark Souls", "Bloodborne", "King's Field"]}
        id={48}
      />
    ),
  },
  {
    id: 49,
    cid: 47,
    taskTitle: "Вопрос 7",
    taskText: "Что такое tileset?",
    taskType: (
      <OneOf
        ans={[
          "Коллекция врагов",
          "Сценарий уровня",
          "Набор маленьких изображений для сборки локаций",
          "Панель инструментов",
        ]}
        id={49}
      />
    ),
  },
  {
    id: 50,
    cid: 48,
    taskTitle: "Вопрос 8",
    taskText:
      "Как называется концепция пути героя придуманная Джозефом Кэмпбеллом?",
    taskType: <InputByUser img={Campbell} g={0} id={50} />,
  },
  {
    id: 51,
    cid: 49,
    taskTitle: "Вопрос 9",
    taskText: "Что такое “черный ящик”?",
    taskType: (
      <OneOf
        ans={[
          "Тестирование игры с точки зрения обычного пользователя",
          "Так называют баги когда новая версия игры ломает функциональность, которая работала в предыдущей версии",
          "Генерация рандомного расположения объектов на локации",
          "Скрытое достижение в видеоиграх",
        ]}
        id={51}
      />
    ),
  },
  {
    id: 52,
    cid: 50,
    taskTitle: "Вопрос 10",
    taskText:
      "Из предложенных игровых механик выберите ту, что не относится к кор-образующим:",
    taskType: (
      <OneOf
        ans={[
          "Прыжок",
          "Покраска участка стены",
          "Удержание баланса персонажа",
          "Любая из вышеописанных механик может образовывать кор",
        ]}
        id={52}
      />
    ),
  },
  {
    id: 53,
    cid: 51,
    taskTitle: "Вопрос 11",
    taskText:
      "Какая игра была первым проектом, в котором использовалась технология 'motion capture'?",
    taskType: (
      <OneOf
        ans={["L.A. Noire", "Half-Life 2", "Max Payne 2", "The Last of Us"]}
        id={53}
      />
    ),
  },
  {
    id: 54,
    cid: 52,
    taskTitle: "Вопрос 12",
    taskText: "Сколько времени займет прохождение игры The Longing?",
    taskType: <InputByUser img={Longing} g={0} id={50} />,
  },
  {
    id: 55,
    cid: 53,
    taskTitle: "Вопрос 13",
    taskText:
      "В какой игре была представлена концепция 'Принципа ограниченной автономности', в котором игроки взаимодействуют с миром, основываясь на их собственных решениях и не следуют фиксированным сюжетным линиям?",
    taskType: (
      <OneOf
        ans={[
          "The Elder Scrolls III: Morrowind",
          "Fallout 3",
          "Skyrim",
          "The Witcher 3: Wild Hunt",
        ]}
        id={55}
      />
    ),
  },
  {
    id: 56,
    cid: 54,
    taskTitle: "Вопрос 14",
    taskText: "Что такое 'система частиц' в разработке игр?",
    taskType: (
      <OneOf
        ans={[
          "Метод создания искусственного интеллекта для врагов",
          "Технология для создания эффектов, таких как дым, огонь, дождь",
          "Система, отвечающая за создание персонажей",
          "Алгоритм расчета физических столкновений объектов",
        ]}
        id={56}
      />
    ),
  },
  {
    id: 57,
    cid: 55,
    taskTitle: "Вопрос 15",
    taskText: "Что подразумевается под термином 'мета' в геймдизайне?",
    taskType: (
      <OneOf
        ans={[
          "Использование в игре информации из реального мира",
          "Действия и стратегии, которые происходят за пределами основной игры (например, оптимизация персонажей или торговля)",
          "Сюжетная линия игры",
          "Это отыгрыш игроком персонажа",
        ]}
        id={57}
      />
    ),
  },
  {
    id: 58,
    cid: 56,
    taskTitle: "Вопрос 16",
    taskText:
      "Какой тип тестирования используется для проверки работы игры на разных платформах?",
    taskType: (
      <OneOf
        ans={[
          "Функциональное тестирование",
          "Регрессионное тестирование",
          "Тестирование совместимости",
          "Модульное тестирование",
        ]}
        id={58}
      />
    ),
  },
  {
    id: 59,
    cid: 57,
    taskTitle: "Вопрос 17",
    taskText:
      "Какая из этих игр была первой, которая использовала технологию создания процедурно генерируемых уровней?",
    taskType: (
      <OneOf ans={["Rogue", "Spelunky", "Minecraft", "No Man’s Sky"]} id={59} />
    ),
  },
  {
    id: 60,
    cid: 58,
    taskTitle: "Вопрос 18",
    taskText: "Сопоставьте фразу и игру:",
    taskType: (
      <Sopostav
        ans={["Morrowind", "Undertale", "WarCraft", "Postal 2", "Half Life 2"]}
        variants={[
          "Проснитесь и пойте, мистер Фриман",
          "Ну ты и соня, тебя даже вчерашний шторм не разбудил",
          "Нужно построить зиккурат",
          "Ха! Никогда не понимал, почему люди не используют в начале самую сильную атаку",
          "Вы не могли бы подписать мою петицию",
        ]}
        id={46}
      />
    ),
  },
  {
    id: 61,
    cid: 59,
    taskTitle: "Вопрос 19",
    taskText:
      "Из предложенных игровых циклов выберите тот, который формирует игровой кор:",
    taskType: (
      <OneOf
        ans={[
          "Накопление вторичного игрового ресурса - покупка новой краски для техники — перекрашивание техники",
          "Перемещение — стрельба по противнику — получение очков",
          "Открытие сундука — получение новой запчасти - продажа запчасти",
          "Получение очков — получение места на лидерборде — получение значка за ранг",
        ]}
        id={61}
      />
    ),
  },
  {
    id: 62,
    cid: 60,
    taskTitle: "Вопрос 20",
    taskText:
      "Как называется архетип персонажа, лукавого, действующего исключительно в в собственных интересах, часто плут, антигерой?",
    taskType: <InputByUser img={trikster} g={0} id={62} />,
  },
  {
    id: 63,
    cid: 61,
    taskTitle: "Вопрос 21",
    taskText:
      "Какой из этих этапов тестирования включает в себя проверку, насколько игра интересна и увлекательна для игроков?",
    taskType: (
      <OneOf
        ans={[
          "Тестирование производительности",
          "Тестирование удобства использования",
          "Игровое тестирование",
          "Тестирование безопасности",
        ]}
        id={63}
      />
    ),
  },
  {
    id: 64,
    cid: 62,
    taskTitle: "Вопрос 22",
    taskText: "Для чего используют матте-пейнтинг в играх?",
    taskType: (
      <OneOf
        ans={[
          "Для создания текстур 3D-моделей",
          "Для освещения",
          "Для создания детализированных фонов",
          "Для написания сценария",
        ]}
        id={64}
      />
    ),
  },
  {
    id: 65,
    cid: 63,
    taskTitle: "Вопрос 23",
    taskText:
      "Какой игровой персонаж был признан самым популярным в истории видеоигр по версии Guinness World Records?",
    taskType: (
      <OneOf ans={["Mario", "Sonic", "Lara Croft", "Master Chief"]} id={65} />
    ),
  },
  {
    id: 66,
    cid: 64,
    taskTitle: "Вопрос 24",
    taskText:
      "В чем заключается разница между 'объектно-ориентированным' и 'процедурным' программированием?",
    taskType: (
      <OneOf
        ans={[
          "Объектно-ориентированное программирование ориентировано на создание объектов и их взаимодействие, а процедурное — на выполнение последовательности шагов",
          "Объектно-ориентированное программирование использует только графику, а процедурное — только текст",
          "Процедурное программирование включает в себя создание графических объектов, а объектно-ориентированное — нет",
          "Разницы нет, это синонимы",
        ]}
        id={66}
      />
    ),
  },
  {
    id: 67,
    cid: 65,
    taskTitle: "Вопрос 25",
    taskText: "Из предложенных игровых механик выберите комплексную.",
    taskType: (
      <OneOf
        ans={[
          "Получение урона от любого источника",
          "Игровой таймер",
          "Скольжение персонажа в положении сидя",
        ]}
        id={67}
      />
    ),
  },
  {
    id: 68,
    cid: 66,
    taskTitle: "Вопрос 26",
    taskText:
      "Какая игра является первой в серии 'Grand Theft Auto', в которой был представлен открытый мир?",
    taskType: (
      <OneOf
        ans={[
          "Grand Theft Auto III",
          "Grand Theft Auto II",
          "Grand Theft Auto",
          "Grand Theft Auto: Vice City",
        ]}
        id={68}
      />
    ),
  },
  {
    id: 69,
    cid: 67,
    taskTitle: "Вопрос 27",
    taskText:
      "Какой из этих типов тестирования является наиболее важным для проверки корректности работы игрового процесса и механик?",
    taskType: (
      <OneOf
        ans={[
          "Функциональное тестирование",
          "Тестирование производительности",
          "Тестирование безопасности",
          "Тестирование локализации",
        ]}
        id={69}
      />
    ),
  },
  {
    id: 70,
    cid: 68,
    taskTitle: "Вопрос 28",
    taskText:
      "Как называется техника создания реалистичного освещения в 3D-играх?",
    taskType: <InputByUser img={dLight} g={0} id={70} />,
  },
  {
    id: 71,
    cid: 69,
    taskTitle: "Вопрос 29",
    taskText:
      "Какой был основной мотив в игре Spec Ops: The Line, которая подвергла критике военную тематику?",
    taskType: (
      <OneOf
        ans={[
          "Сражение с зомби",
          "Выживание на выжженной земле",
          "Дегуманизация солдат в условиях войны",
          "Возвращение в родную страну после войны",
        ]}
        id={71}
      />
    ),
  },
  {
    id: 72,
    cid: 70,
    taskTitle: "Вопрос 30",
    taskText:
      "Как называется фирменный знак серии игр симс, также обозначающий активного персонажа в игре?",
    taskType: <InputByUser img={sims} g={0} id={72} />,
  },
  {
    id: 73,
    cid: 71,
    taskTitle: "Вопрос 31",
    taskText: "Что такое 'game loop'?",
    taskType: (
      <OneOf
        ans={[
          "Это набор игровых уровней, через которые проходит игрок",
          "Это цикл, который обновляет логику игры и рисует кадры на экране",
          "Это сценарий, который управляет действиями персонажа",
          "Это музыка, которая воспроизводится в игре",
        ]}
        id={73}
      />
    ),
  },
  {
    id: 74,
    cid: 72,
    taskTitle: "Вопрос 32",
    taskText:
      "Какой элемент механики был впервые введён в игры серии Metroid Prime?",
    taskType: (
      <OneOf
        ans={[
          "Шлем с видом от первого лица",
          "Система улучшений для снаряжения",
          "Механика укрытий",
          "Многопользовательская игра",
        ]}
        id={74}
      />
    ),
  },
  {
    id: 75,
    cid: 73,
    taskTitle: "Вопрос 33",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={h1} id={75} />,
  },
  {
    id: 76,
    cid: 74,
    taskTitle: "Вопрос 34",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={h2} id={76} />,
  },
  {
    id: 77,
    cid: 75,
    taskTitle: "Вопрос 35",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={h3} id={77} />,
  },
  {
    id: 78,
    cid: 76,
    taskTitle: "Вопрос 36",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={h4} id={78} />,
  },
  {
    id: 79,
    cid: 77,
    taskTitle: "Вопрос 37",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={h5} id={79} />,
  },
  {
    id: 80,
    cid: 78,
    taskTitle: "Вопрос 38",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={h6} id={80} />,
  },
  {
    id: 81,
    cid: 79,
    taskTitle: "Вопрос 39",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={h7} id={81} />,
  },
  {
    id: 82,
    cid: 80,
    taskTitle: "Вопрос 40",
    taskText: "Что за игра на картинке?",
    taskType: <InputByUser img={h8} id={82} />,
  },
  {
    id: 83,
    taskTitle: "Итоги",
    taskText: "спасибо за участие в нашем квесте",
    taskType: <Results id={99} />,
  },
];
