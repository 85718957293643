import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import "./admin.css"; // Подключаем CSS для стилей

const Admin = () => {
  const [data, setData] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    checkRole();
  }, []);

  const checkRole = async () => {
    try {
      const response = await axios.get("/api/Adminka");
      setData(response.data);
      setIsActive(true);
    } catch (e) {
      alert("Куда это мы лезем??");
      window.location.replace("/");
    }
  };

  const columns = [
    { field: "email", headerName: "Email", width: 200 },
    { field: "nickname", headerName: "Никнейм", width: 150 },
    { field: "fio", headerName: "ФИО", width: 200 },
    { field: "birthday", headerName: "Дата рождения", width: 150 },
    { field: "schoolname", headerName: "Школа", width: 200 },
    { field: "schoolclass", headerName: "Класс", width: 100 },
    { field: "number", headerName: "Номер", width: 150 },
    { field: "role", headerName: "Роль", width: 150 },
    { field: "score", headerName: "Баллы", width: 100 },
  ].map((col) => ({
    ...col,
    headerClassName: selectedColumn === col.field ? "selected-column" : "",
    cellClassName: (params) =>
      selectedColumn === params.field ? "selected-cell" : "",
    sortable: true,
  }));

  const handleColumnHeaderClick = (field) => {
    setSelectedColumn(field === selectedColumn ? null : field);
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection.rowIds);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "90vh" }}>
      <DataGrid
        slots={{
          toolbar: GridToolbar,
        }}
        rows={data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[1000]}
        getRowId={(row) => row.email}
        disableSelectionOnClick
        sortingOrder={["asc", "desc"]}
        checkboxSelection
        density="compact"
        disableRowSelectionOnClick
        sx={{ backgroundColor: "rgb(255, 255, 255, 0.5)" }}
        onColumnHeaderClick={(params) => handleColumnHeaderClick(params.field)}
        onSelectionModelChange={handleSelectionChange}
        selectionModel={selectedRows}
      />
    </Box>
  );
};

export default Admin;
