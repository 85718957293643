import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import btnNext from "../../images/btnNext.png";
import btnBack from "../../images/btnBack.png";
import flag from "../../images/flag.png";
import React, { useState, useEffect } from "react";
import axios from "axios";

const Svitok = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentAns, setCurrentAns] = useState(null);
  // Функция для обновления значения ans
  const handleAnsUpdate = (newAns) => {
    setCurrentAns(newAns);
  };
  const [isDisabled, setIsDisabled] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(true);
  const [isAnswerOpen, setIsAnswerOpen] = useState(false);
  const [answerData, setAnswerData] = useState(true);
  const [ans, setAns] = useState("");
  useEffect(() => {
    // Уведомляем Svitok об изменении ans
    if (props.onAnsChange) {
      props.onAnsChange(ans);
    }
  }, [ans]);

  useEffect(() => {
    if (currentAns) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [currentAns]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: 4,
        mt: isMobile ? 5 : 0,
      }}
    >
      <Box
        sx={{
          ml: isMobile ? 2 : 0,
          width: "70%",
          mb: -3,
          alignSelf: "start",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <img
          src={flag}
          alt="Флаг"
          style={{
            width: "290px",
            height: "100px",
          }}
        />
        <Typography
          sx={{
            position: "absolute",
            color: "white",
            textAlign: "center",
            fontSize: 32,
            ml: -5,
          }}
        >
          {props.flagText}
        </Typography>
      </Box>

      <Box
        sx={{
          width: "90%",
          padding: 3,
          borderRadius: "16px",
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff8e1",
          display: "flex",
          overflow: "visible",
          justifyContent: isMobile ? "center" : "space-between",
          gap: 2,
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            display: isMobile ? "none" : "flex",
            ml: -5,
            mt: -7,
            mb: -7,
            width: { sm: "55px", xl: "60px" },
            minWidth: "50px",
            backgroundColor: "#f4ecd8",
            borderRadius: "10px",
            border: "2px solid #d3c4a5",
            boxShadow: "0px 2px 6px rgba(0,0,0,0.2)",
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            pb: 2,
            width: isMobile ? "95%" : "80%",
          }}
        >
          <Typography
            sx={{
              color: "rgba(27, 55, 54, 1)",
              fontSize: { xs: 26, sm: 30, xl: 30 },
              textTransform: "uppercase",
              fontWeight: "bold",
              border: 0,
              borderBottom: 2,
              borderStyle: "dashed",
              borderColor: "rgba(148, 129, 121, 1)",
              pb: 2,
              mb: 3,
              width: "100%",
            }}
          >
            {props.zagolovok}
          </Typography>
          {React.cloneElement(props.chtoRisyem, {
            onAnsChange: handleAnsUpdate, // Передаем функцию
            modalOpen: modalOpen,
            setModalOpen: setModalOpen,
            modalData: modalData,
            setModalData: setModalData,
            answerData: answerData,
            isAnswerOpen: isAnswerOpen,
            data: props.data.nickname,
            isHard: props.isHard,
            userRole: props.userRole,
          })}
        </Box>
        <Box
          sx={{
            display: isMobile ? "none" : "flex",
            mr: -5,
            mt: -7,
            mb: -7,
            width: { sm: "55px", xl: "60px" },
            minWidth: "50px",
            backgroundColor: "#f4ecd8",
            borderRadius: "10px",
            border: "2px solid #d3c4a5",
            boxShadow: "0px 2px 6px rgba(0,0,0,0.2)",
          }}
        ></Box>
      </Box>

      <Box
        sx={{
          zIndex: 0,
          mt: -5,
          display: "flex",
          width: "80%",
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={props.navigationHandlers.back}
          sx={{ ml: isMobile ? -3 : 0 }}
        >
          <img
            src={btnBack}
            alt="Назад"
            style={{
              width: "60px",
              height: isMobile ? "120px" : "160px",
              alignSelf: "center",
            }}
          />
        </Button>
        <Box
          sx={{
            alignSelf: "flex-end",
            display: props.taskId == 0 || props.taskId == 100 ? "none" : "flex",
            flexDirection: "column",
            width: { xs: "60%", sm: "50%", xl: "35%" },
          }}
        >
          <Button
            disabled={!isDisabled}
            fullWidth
            variant="contained"
            sx={{
              mt: 7,
              mb: 1,
              padding: 1,
              fontSize: 18,
              backgroundColor: "rgba(38, 121, 137, 1)",
              textTransform: "capitalize",
              borderRadius: "15px",
            }}
            onClick={async () => {
              try {
                const requestBody = {
                  task_id: props.taskId,
                  answer: currentAns + "",
                };
                const response = await axios.post(
                  `api/task_check`,
                  {},
                  {
                    params: requestBody,
                  }
                );
                setModalData(response.data.status);
                setModalOpen(true);
                const mode = await axios.post("/api/hard_mode_check");
                props.setIsHard(mode.data);
              } catch (error) {
                setModalData(
                  "Серверные шоколадки, попробуйте еще раз отправить ответ"
                );
                setModalOpen(true);
              }
            }}
          >
            Отправить
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{
              mb: 2,
              padding: 1,
              fontSize: 18,
              backgroundColor: "rgba(249, 239, 229, 1)",
              textTransform: "capitalize",
              borderRadius: "15px",
              borderColor: "rgba(148, 129, 121, 1)",
              border: "2px solid rgba(148, 129, 121, 1)",
              color: "rgba(148, 129, 121, 1)",
            }}
            onClick={async () => {
              setIsAnswerOpen(true);
              try {
                const response = await axios.get("/api/task_answer", {
                  params: {
                    task_id: props.taskId,
                  },
                });
                setAnswerData(response.data.status);
              } catch (error) {
                setAnswerData("Серверные шоколадки, попробуйте еще раз");
              }
            }}
          >
            Ответ
          </Button>
        </Box>
        <Button
          onClick={props.navigationHandlers.next}
          sx={{ mr: isMobile ? -3 : 0 }}
        >
          <img
            src={btnNext}
            alt="Вперед"
            style={{
              width: "60px",
              height: isMobile ? "120px" : "160px",
              alignSelf: "center",
              display:
                props.isHard === false && props.taskId == 100
                  ? "none"
                  : "block",
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};
export default Svitok;
