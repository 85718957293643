import React, { useEffect } from "react";
import {
  Drawer,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ThemeProvider,
  createTheme,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { ExpandMore, Logout } from "@mui/icons-material";
import axios from "axios";

export const NavigationDrawer = ({
  isOpen,
  onClose,
  tasksData,
  value,
  handleChange,
  isHard,
  data,
}) => {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChangePanelka = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const theme = createTheme({
    /*ничего не дает. Ели что потом удалить. Но лучше еще раз потестить. Если стрелки внизу не появятся - убрать*/
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "rgba(38, 121, 137, 1)", // Установите цвет стрелок
          },
        },
      },
    },
  });

  return (
    <div style={{ position: "relative" }}>
      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backdropFilter: "blur(5px)", // Добавляем размытие
            backgroundColor: "rgba(0, 0, 0, 0.3)", // Добавляем полупрозрачный фон
            zIndex: 1000, // Убедитесь, что он находится над остальным контентом
          }}
        />
      )}
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: "250px",
            justifyContent: "space-between",
            backgroundColor: "rgba(255, 246, 235, 1)",
            borderRadius: "15px 0 0 15px",
            zIndex: 1001, // Убедитесь, что Drawer находится над размытым фоном
          },
        }}
      >
        <Box>
          <Box
            sx={{
              width: "100%",
              padding: 2,
              boxSizing: "border-box",
              pb: 2,
              border: 0,
              borderBottom: 2,
              borderStyle: "dashed",
              borderColor: "rgba(148, 129, 121, 1)",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "rgba(42, 83, 81, 1)",
              }}
            >
              Ваш ник:
            </Typography>
            <Typography
              sx={{ fontSize: "16px", ml: 2, color: "rgba(42, 83, 81, 1)" }}
            >
              {data.nickname}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "rgba(42, 83, 81, 1)",
              }}
            >
              Ваша почта:
            </Typography>
            <Typography
              sx={{ fontSize: "16px", ml: 2, color: "rgba(42, 83, 81, 1)" }}
            >
              {data.email}
            </Typography>
          </Box>
          <Accordion
            elevation={0}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 0)",
            }}
            expanded={expanded === "panel1"}
            onChange={handleChangePanelka("panel1")}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore
                  sx={{
                    color: "rgba(38, 121, 137, 1)",
                  }}
                />
              }
            >
              <Typography sx={{ fontSize: 20 }}>Обычный режим</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ThemeProvider theme={theme}>
                <Tabs
                  sx={{ maxHeight: "30vh", minHeight: "250px", mt: -3 }}
                  orientation="vertical"
                  variant="scrollable"
                  textColor="inherit"
                  value={value}
                  onChange={handleChange}
                  TabIndicatorProps={{
                    sx: {
                      backgroundColor: "rgba(38, 121, 137, 1)", // Боковая полоска зеленого цвета
                    },
                  }}
                >
                  {tasksData.map((task) =>
                    task.id < 42 ? (
                      <Tab
                        key={task.id}
                        sx={{
                          color: "black",
                          fontSize: 20,
                          textTransform: "capitalize",
                          "&.Mui-selected": {
                            color: "rgba(38, 121, 137, 1)", // Цвет текста красный для активного Tab
                          },
                        }}
                        label={task.taskTitle}
                      />
                    ) : (
                      <Tab
                        key={task.id}
                        sx={{ display: "none" }}
                        label={task.taskTitle}
                      />
                    )
                  )}
                </Tabs>
              </ThemeProvider>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            expanded={expanded === "panel2"}
            onChange={handleChangePanelka("panel2")}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 0)",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore
                  sx={{
                    color: "rgba(38, 121, 137, 1)",
                  }}
                />
              }
            >
              <Typography sx={{ fontSize: 20 }}>Хард режим</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ThemeProvider theme={theme}>
                <Tabs
                  sx={{ maxHeight: "30vh", minHeight: "250px", mt: -4 }}
                  orientation="vertical"
                  variant="scrollable"
                  textColor="inherit"
                  value={value}
                  onChange={handleChange}
                  TabIndicatorProps={{
                    sx: {
                      backgroundColor: "rgba(38, 121, 137, 1)", // Боковая полоска зеленого цвета
                    },
                  }}
                >
                  {tasksData.map((task) =>
                    task.id > 41 ? (
                      <Tab
                        disabled={
                          task.id !== 42 && !isHard === true ? true : false
                        }
                        key={task.id}
                        sx={{
                          color: "black",
                          fontSize: 20,
                          textTransform: "capitalize",
                          "&.Mui-selected": {
                            color: "rgba(38, 121, 137, 1)", // Цвет текста красный для активного Tab
                          },
                        }}
                        label={task.taskTitle}
                      />
                    ) : (
                      <Tab
                        key={task.id}
                        sx={{ display: "none" }}
                        label={task.taskTitle}
                      />
                    )
                  )}
                </Tabs>
              </ThemeProvider>
            </AccordionDetails>
          </Accordion>
        </Box>
        {/*
        <Button
          onClick={async () => {
            try {
              await axios.post("/api/auth/jwt/logout");
            } catch {
              alert("Какие-то ошибки с logout");
            }
          }}
          sx={{
            color: "rgba(38, 121, 137, 1)",
            justifySelf: "flex-end",
          }}
        >
          <Logout sx={{ fontSize: "36px", color: "rgba(38, 121, 137, 1)" }} />
        </Button>*/}
      </Drawer>
    </div>
  );
};
