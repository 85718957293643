import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import bard from "../../images/classes/bard.png";
import fight from "../../images/classes/fight copy.png";
import investigator from "../../images/classes/investigator copy.png";
import multi from "../../images/classes/multi copy.png";
import roge from "../../images/classes/roge copy.png";
import wizard from "../../images/classes/wizard copy.png";

export const Results = (props) => {
  const [chto, setChto] = React.useState(null);
  const [mode, setMode] = React.useState(null);
  const descriptions = {
    Мультикласс: {
      text: "ты что-то уникальное, ты и есть сам хаос! Ты непредсказуемый и многогранный. Твои способности сочетают лучшее из разных путей, но помни: именно твоя непохожесть делает тебя особенным, ведь в тебе кроется сила создавать своё собственное предназначение.",
      image: multi,
    },
    Маг: {
      text: "ты — маг-художник, чьи кисти творят магию, а воображение рисует миры. Одним движением ты можешь оживить краски или затушевать тьму, но помни: каждое создание несёт в себе частицу тебя.",
      image: wizard,
    },
    Бард: {
      text: "ты — бард-нарративный дизайнер, ткач историй, наполняющий миры мелодией слов и смыслов. Твоя сила — в умении оживить любое приключение, но помни: каждая нить повествования должна звучать гармонично, иначе всё рассыплется в хаос.",
      image: bard,
    },
    Следопыт: {
      text: "ты — следопыт-тестировщик, чьё острое чутьё улавливает каждую трещину в мире. Твоя миссия — находить скрытые ловушки и исправлять их, чтобы путь стал чистым, но будь осторожен: за каждым углом может скрываться новый баг.",
      image: investigator,
    },
    Воин: {
      text: "ты — воин-кодер, с умом, острым как меч, и железной хваткой, готовый исправлять баги реальности. Не забывай: код мира сложен, одно неверное движение — и всё может рухнуть!",
      image: fight,
    },
    Разбойник: {
      text: "Ты — разбойник-геймдизайнер, ловко крадущий идеи у хаоса и превращающий их в захватывающие миры. Твоя хитрость и смекалка способны обойти любые преграды, но помни: каждая игра — это риск, где одно неверное решение может разрушить всё.",
      image: roge,
    },
  };
  const dataRefresh = async () => {
    const res = await axios.get("/api/user_info");
    setChto(res.data.role);
  };

  useEffect(() => {
    if (props.isHard) {
      axios.post("api/choose_role");
      dataRefresh();
    }
  }, [props.isHard]);

  return props.isHard === false ? (
    <>
      <Typography sx={{ fontSize: "20px" }}>
        У Вас нет пока доступа к итогам. Для просмотра результатов и определения
        Вашей направленности необходимо решить все задания обычного режима.
      </Typography>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "start",
      }}
    >
      <>
        <Typography sx={{ fontSize: "20px" }}>
          Теперь нам все понятно{" "}
          {chto !== null && chto !== undefined &&  descriptions[chto].text
            ? descriptions[chto].text
            : "Ошибка загрузки("}
        </Typography>
        <Box
          sx={{
            width: "60%",
            alignSelf: "center",
            mt: 2,
            mb: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={
              chto !== null && chto !== undefined && descriptions[chto].image
                ? descriptions[chto].image
                : { bard }
            }
            style={{
              width: "100%",
              maxWidth: "350px",
              height: "auto",
              alignSelf: "center",
            }}
          />
        </Box>
        <Typography sx={{ fontSize: "20px" }}>
          Однако это не конец твоего путешествия. Загляни на доску лидеров,
          чтобы узнать насколько ты могуществен.
          <br />
          Вернись на эту страницу в 10 декабря после 18.00, чтобы узнать ответы
          на вопросы. Помни, только избранные доходят до конца пути!
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            
          }}
        >
          <br />
          Если же твоя тяга к загадкам все еще не утолена, то ты можешь пройти
          хард-режим, там тебя будет ждать ещё больше логических загадок. Для перехода в него выберите соответствующую кнопку в панели навигации.
        </Typography>
      </>
    </Box>
  );
};
