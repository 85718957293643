import * as React from "react";
import Box from "@mui/material/Box";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import titles from "../images/titles.png";
import axios from "axios";

export default function RightPath(props) {
  const [data, setData] = React.useState([
    { place: 1, school: "Крыло самолета", score: 20 },
  ]);
  const [userData, setUserData] = React.useState([
    { place: 1, nickname: "Крыло самолета", score: 20 },
  ]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Функция для загрузки данных о топ-школах
  const leadersRefresh = async () => {
    try {
      if (props.isAuth === true) {
        const res = await axios.post("/api/top");
        setData(res.data);
        const userRes = await axios.get("/api/self_place");
        setUserData(userRes.data);
      }
    } catch {
      setData([{ place: 1, nickname: "Ошибка загрузки данных", score: 0 }]);
    }
  };

  // Вызов функции leadersRefresh при монтировании компонента и установка интервала
  React.useEffect(() => {
    // Загрузка данных при монтировании компонента
    leadersRefresh();
    // Установка интервала для обновления данных каждые 5 мин
    const intervalId = setInterval(leadersRefresh, 120000);
    // Очистка интервала при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "90%", sm: "30%", md: "25%" },
        mt: { xs: 0, sm: 10, xl: 10 },
        justifyContent: "space-between",
        pr: { xs: 0, sm: 3, md: 3 },
        mr: 1,
        alignSelf: isMobile ? "center" : "",
        mb: isMobile ? 4 : 0,
      }}
    >
      <Box
        sx={{
          backgroundColor: isMobile ? "#fff8e1" : "",
          borderRadius: isMobile ? "15px" : "",
          paddingTop: isMobile ? 2 : 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            mr: isMobile ? 3 : 0,
            ml: isMobile ? 3 : 0,
          }}
        >
          <Box
            sx={{
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${titles})`,
              backgroundSize: "100% 100%",
              p: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                color: "white",
                fontSize: "20px",
                textTransform: "uppercase",
                pr: 2,
                pl: 2,
              }}
            >
              <b>Таблица лидеров</b>
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 3,
              color: "black",
              mb: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "90%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "90%",
                px: 2,
                pb: 2,
                fontSize: 16,
                color: "rgba(27, 55, 54, 1)",
              }}
            >
              <Typography
                sx={{ flex: 1, textAlign: "center", fontWeight: "bold" }}
              >
                №
              </Typography>
              <Typography
                sx={{ flex: 3, textAlign: "center", fontWeight: "bold" }}
              >
                Ник
              </Typography>
              <Typography
                sx={{ flex: 1, textAlign: "center", fontWeight: "bold" }}
              >
                Баллы
              </Typography>
            </Box>

            {data.map((item, index) =>
              index < 3 /*кол-во лидеров, которое отрисовываем*/ ? (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "90%",
                    px: 2,
                    py: 1,
                    borderBottom: "1px solid rgba(38, 121, 137, 1)",
                    "&:last-child": {
                      borderBottom: "none",
                    },
                    color: "rgba(27, 55, 54, 1)",
                  }}
                >
                  <Typography sx={{ flex: 1, textAlign: "center" }}>
                    {index + 1}
                  </Typography>
                  <Typography sx={{ flex: 3, textAlign: "center" }}>
                    {item.nickname}
                  </Typography>
                  <Typography sx={{ flex: 1, textAlign: "center" }}>
                    {item.score}
                  </Typography>
                </Box>
              ) : (
                <></>
              )
            )}
            <Typography
              sx={{
                width: "90%",
                px: 2,
                py: 1,
                borderBottom: "1px solid rgba(38, 121, 137, 1)",
                "&:last-child": {
                  borderBottom: "none",
                },
                color: "rgba(27, 55, 54, 1)",
                textAlign: "center",
              }}
            >
              <b>...</b>
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "90%",
                px: 2,
                py: 1,
                borderBottom: "1px solid rgba(38, 121, 137, 1)",
                "&:last-child": {
                  borderBottom: "none",
                },
                color: "rgba(27, 55, 54, 1)",
              }}
            >
              <Typography sx={{ flex: 1, textAlign: "center" }}>
                {userData.place}
              </Typography>
              <Typography sx={{ flex: 3, textAlign: "center" }}>
                {userData.nickname}
              </Typography>
              <Typography sx={{ flex: 1, textAlign: "center" }}>
                {userData.score}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
