import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Send } from "../information/send";
import { Answer } from "../information/answer";

export const OneOf = (props) => {
  const [ans, setAns] = useState("");
  useEffect(() => {
    if (props.onAnsChange) {
      props.onAnsChange(ans);
    }
  }, [ans]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        flexDirection: { xs: "column", xl: "row" },
        height: "100%",
        gap: { xs: 2, sm: 2, xl: 4 },
      }}
    >
      <FormControl sx={{ mt: { sm: 0, xl: 3 }, width: "80%" }}>
        <RadioGroup
          sx={{
            display: "grid",
            gap: 2, // Расстояние между элементами
            justifyContent: "space-between",
            mb: 4,
            gridTemplateColumns: { xs: "1fr", xl: "1fr 1fr" }, // Две колонки одинаковой ширины
            justifyItems: "stretch",
            ml: { xs: 0, sm: 0 },
            textAlign: "start",
          }}
        >
          {props.ans.map((value, index) => (
            <FormControlLabel
              onClick={() => {
                setAns(index + 1);
              }}
              key={index}
              sx={{ alignItems: "flex-start" }}
              labelPlacement="end"
              value={value}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 18,
                      color: "rgba(38, 121, 137, 1)",
                    },
                  }}
                />
              }
              label={<span style={{ fontSize: "20px" }}>{value}</span>}
            />
          ))}
        </RadioGroup>
        <Send
          modalData={props.modalData}
          modalOpen={props.modalOpen}
          setModalOpen={props.setModalOpen}
        />
        <Answer
          isAnswerOpen={props.isAnswerOpen}
          answerData={props.answerData}
        />
      </FormControl>
    </Box>
  );
};
