import { Box, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Send } from "../information/send";
import styled from "styled-components";
import { Answer } from "../information/answer";

const GrTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(198, 186, 171, 1)",
      borderRadius: 15,
      borderWidth: 2,
    },
    "&:hover fieldset": {
      borderColor: "rgba(158, 158, 116, 1)",
      borderRadius: 15,
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(198, 186, 171, 1)",
      borderRadius: 15,
    },
    "& input": {
      backgroundColor: "rgba(255, 253, 251, 1)",
      borderRadius: 15,
      margin: 7,
      fontSize: 20,
    },
  },
  "& label": {
    transform: "translate(50%, 100%) scale(1)",
    "&.MuiInputLabel-shrink": {
      transform: "translate(30%, -50%) scale(0.75)",
      color: "rgba(148, 129, 121, 1)",
      marginLeft: -5,
    },
  },
});

export const InputByUser = (props) => {
  const [ans, setAns] = useState("");
  useEffect(() => {
    if (props.onAnsChange) {
      props.onAnsChange(ans);
    }
  }, [ans]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
          gap: { xs: 2, sm: 2, xl: 4 },
        }}
      >
        <Box sx={{ width: "80%" }}>
          <img
            src={props.img}
            alt="Картинка задания"
            style={{
              width: "100%",
              maxWidth: "350px",
              height: "auto",
              alignSelf: "center",
              marginBottom: 5,
            }}
          />
        </Box>
        <Typography sx={{ display: props.g === 0 ? "none" : "block" }}>
          Название игр необходимо вводить на английском языке!
        </Typography>
        <GrTextField
          label={`Ваш ответ`}
          fullWidth
          onChange={(e) => setAns(e.target.value)}
        />
        <Send
          modalData={props.modalData}
          modalOpen={props.modalOpen}
          setModalOpen={props.setModalOpen}
        />
        <Answer
          isAnswerOpen={props.isAnswerOpen}
          answerData={props.answerData}
        />
      </Box>
    </Box>
  );
};
