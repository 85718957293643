import * as React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import logoWeekend from "../images/logoWeekend.png";
import logo from "../images/logom.png";
import { Call, LocationOn } from "@mui/icons-material";
import vk from "../images/VK Circled.png";
import tg from "../images/Telegram App.png";
import site from "../images/Website.png";

const linkStyle = {
  color: "black",
  textDecoration: "none",
};

export default function Footer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        display: "flex",
        textAlign: "center",
        pt: 3,
        pb: 2,
        backgroundColor: "rgba(53, 110, 117, 1)",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        gap: isMobile ? 3 : 7,
        flexWrap: "wrap",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <Box display="flex" sx={{ width: isMobile ? "80%" : "20%" }}>
        <LocationOn />
        <Typography sx={{ textAlign: "start", ml: 2 }}>
          Красноярский край, г. Норильск, <br />
          ул. 50 лет Октября, д. 10
        </Typography>
      </Box>

      <Box
        sx={{
          display: isMobile ? "none" : "flex",
          gap: 5,
          width: "30%",
          justifyContent: "center",
          minWidth: "350px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
          }}
        >
          <img
            src={logoWeekend}
            alt="Лого викенда"
            style={{
              maxHeight: "60px",
              height: "auto",
              alignSelf: "center",
            }}
          />
          <Link to={"http://itweekend.ru"} style={linkStyle}>
            IT-Weekend | Norilsk 2k24
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <img
            src={logo}
            alt="Лого викенда"
            style={{
              maxHeight: "60px",
              height: "auto",
              alignSelf: "center",
            }}
          />
          <Link to={"https://kvantorium-norilsk.ru"} style={linkStyle}>
            IT-Куб.Норильск
          </Link>
        </Box>
      </Box>

      <Box display="flex" sx={{ width: isMobile ? "80%" : "20%" }}>
        <Call />
        <Typography sx={{ fontSize: 18, fontWeight: "bold", ml: 2 }}>
          8(913)-504-33-77,
          <br /> 8(913)-504-33-00
          <br />
          <span style={{ fontSize: 14, fontWeight: "normal" }}>
            Пн-Сб с 12:00 до 20:00
          </span>
        </Typography>
      </Box>

      <Box
        sx={{
          display: isMobile ? "flex" : "none",
          gap: 5,
          width: "30%",
          justifyContent: "center",
          minWidth: "350px",
        }}
      >
        <Link to={"https://kvantorium-norilsk.ru"} style={linkStyle}>
          <img
            src={site}
            alt="vk"
            style={{
              maxHeight: "30px",
              height: "auto",
              alignSelf: "center",
            }}
          />
        </Link>
        <Link to={"https://t.me/itcubenorilsk"} style={linkStyle}>
          <img
            src={tg}
            alt="tg"
            style={{
              maxHeight: "30px",
              height: "auto",
              alignSelf: "center",
            }}
          />
        </Link>
        <Link to={"https://vk.com/itcube.norilsk"} style={linkStyle}>
          <img
            src={vk}
            alt="vk"
            style={{
              maxHeight: "30px",
              height: "auto",
              alignSelf: "center",
            }}
          />
        </Link>
      </Box>
    </Box>
  );
}
