import React, { useEffect } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import titles from "../../images/titles.png";
import { ExpandMore } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const NavigationPanel = ({
  tasksData,
  value,
  handleChange,
  isHard,
  data,
}) => {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChangePanelka = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const theme = createTheme({
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "rgba(38, 121, 137, 1)", // Установите цвет стрелок
          },
        },
      },
    },
  });

  return (
    <Box
      sx={{
        display: { xs: "none", sm: "flex" },
        width: { sm: "20%" },
        flexDirection: "column",
        alignItems: "center",
        m: 2,
        mt: { xs: 2, sm: 10, xl: 10 },
      }}
    >
      <Box
        sx={{
          /*backgroundSize: "contain",*/
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${titles})`,
          backgroundSize: "100% 100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          width: "100%",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            color: "white",
            fontSize: "20px",
            textTransform: "uppercase",
          }}
        >
          <b>Навигация</b>
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          borderRadius: "15px 15px 0 0",
          backgroundColor: "rgba(255, 255, 255, 0.79)",
          mt: 2,
          padding: 2,
          boxSizing: "border-box",
        }}
      >
        <Typography sx={{ fontSize: "18px" }}>Ваш ник:</Typography>
        <Typography sx={{ fontSize: "18px", ml: 2 }}>
          {data.nickname}
        </Typography>
        <Typography sx={{ fontSize: "18px" }}>Ваша почта:</Typography>
        <Typography sx={{ fontSize: "18px", ml: 2 }}>{data.email}</Typography>
      </Box>
      <Accordion
        sx={{
          width: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.53)",
          borderRadius: expanded === "panel1" ? "15px 15px 5px 5px" : 0,
        }}
        expanded={expanded === "panel1"}
        onChange={handleChangePanelka("panel1")}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMore
              sx={{
                color: "rgba(38, 121, 137, 1)",
              }}
            />
          }
        >
          <Typography sx={{ fontSize: 20 }}>Обычный режим</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ThemeProvider theme={theme}>
            <Tabs
              sx={{ maxHeight: "30vh", minHeight: "250px", mt: -4 }}
              orientation="vertical"
              variant="scrollable"
              textColor="inherit"
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                sx: {
                  backgroundColor: "rgba(38, 121, 137, 1)", // Боковая полоска зеленого цвета
                },
              }}
            >
              {tasksData.map((task) =>
                task.id < 42 ? (
                  <Tab
                    key={task.id}
                    sx={{
                      color: "black",
                      fontSize: 20,
                      textTransform: "capitalize",
                      "&.Mui-selected": {
                        color: "rgba(38, 121, 137, 1)", // Цвет текста красный для активного Tab
                      },
                    }}
                    label={task.taskTitle}
                  />
                ) : (
                  <Tab
                    key={task.id}
                    sx={{ display: "none" }}
                    label={task.taskTitle}
                  />
                )
              )}
            </Tabs>
          </ThemeProvider>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChangePanelka("panel2")}
        sx={{
          width: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.53)",
          borderRadius: expanded === "panel2" ? "15px 15px 0 0" : 0,
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMore
              sx={{
                color: "rgba(38, 121, 137, 1)",
              }}
            />
          }
        >
          <Typography sx={{ fontSize: 20 }}>Хард режим</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ThemeProvider theme={theme}>
            <Tabs
              sx={{ maxHeight: "30vh", minHeight: "250px", mt: -4 }}
              orientation="vertical"
              variant="scrollable"
              textColor="inherit"
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                sx: {
                  backgroundColor: "rgba(38, 121, 137, 1)", // Боковая полоска зеленого цвета
                },
              }}
            >
              {tasksData.map((task) =>
                task.id > 41 ? (
                  <Tab
                    disabled={task.id !== 42 && !isHard === true ? true : false}
                    key={task.id}
                    sx={{
                      color: "black",
                      fontSize: 20,
                      textTransform: "capitalize",
                      "&.Mui-selected": {
                        color: "rgba(38, 121, 137, 1)", // Цвет текста красный для активного Tab
                      },
                    }}
                    label={task.taskTitle}
                  />
                ) : (
                  <Tab
                    key={task.id}
                    sx={{ display: "none" }}
                    label={task.taskTitle}
                  />
                )
              )}
            </Tabs>
          </ThemeProvider>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
