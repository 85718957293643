import { Box, Typography } from "@mui/material";

export const HardMain = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <Typography sx={{ fontSize: "20px" }}>
        Хард-режим — испытание для истинных героев, готовых проверить свои
        пределы. Этот режим включает: <br />
        Больше вопросов, чтобы глубже проверить твои знания по играм.
        Дополнительные баллы, которые могут поднять тебя на вершину доски
        лидеров и приблизить к статусу легенды. <br />
        <br />
        Количество вопросов в хард-режиме 40 штук...
        <br />
        Только самые отважные и решительные смогут пройти этот путь. Готов ли ты
        принять вызов и доказать свою силу?
      </Typography>
      <Typography
        sx={{
          fontSize: "24px",
          display: props.isHard === false ? "block" : "none",
        }}
      >
        <br />
        Задния из этого блока станут доступны только после прохождения обычного
        режима
      </Typography>
    </Box>
  );
};
