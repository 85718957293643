import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Send } from "../information/send";
import { Answer } from "../information/answer";

export const Sopostav = (props) => {
  // Состояние для хранения выбранных индексов
  const [selectedIndices, setSelectedIndices] = useState(
    Array(props.variants.length).fill("") // Изначально массив пуст
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Обработчик изменения выбранного значения
  const handleSelectChange = (index, value) => {
    const updatedIndices = [...selectedIndices];
    updatedIndices[index] = value;
    setSelectedIndices(updatedIndices);

    // Выводим выбранные индексы в виде строки
    const resultString = updatedIndices
      .map((val) => (val !== "" ? val + 1 : ""))
      .join("");
    if (resultString.length === props.variants.length) {
      props.onAnsChange(resultString);
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "grid",
        gap: 2,
        gridTemplateColumns: "1fr",
      }}
    >
      {props.variants.map((variant, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            gap: isMobile ? 2 : 4,
            padding: 1,
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              width: "40px",
            }}
          >
            {index + 1}
          </Typography>

          <Typography sx={{ flex: 1, fontSize: "18px", textAlign: "start" }}>
            {variant}
          </Typography>

          <FormControl
            fullWidth
            sx={{
              color: "rgba(0, 0, 0, 0.7)",
              minWidth: "150px",
              flex: 1,
              textAlign: "start",
              borderRadius: "15px",
              "& .MuiOutlinedInput-root": {
                backgroundColor: "rgba(255, 253, 251, 1)",
                borderRadius: "15px",
                "&.Mui-focused fieldset": {
                  color: "rgba(0, 0, 0, 0.7)",
                  borderColor: "rgba(198, 186, 171, 1)",
                },
                "&:hover fieldset": {
                  borderWidth: 2,
                  borderColor: "rgba(198, 186, 171, 1)", // Цвет рамки при наведении
                },
              },
            }}
          >
            <InputLabel
              id={`select-label-${index}`}
              sx={{
                color: "rgba(0, 0, 0, 0.7)", // Цвет текста
                "&.Mui-focused": {
                  color: "rgba(38, 121, 137, 1)", // Цвет текста при фокусе
                },
              }}
            >
              Выберите ответ
            </InputLabel>
            <Select
              labelId={`select-label-${index}`}
              label={"Выберите ответ"}
              value={selectedIndices[index]}
              onChange={(e) => handleSelectChange(index, e.target.value)}
              sx={{
                borderRadius: "15px",
                "& .MuiSelect-icon": {
                  color: "rgba(38, 121, 137, 1)", // Цвет стрелки
                },
              }}
            >
              {props.ans.map((answer, ansIndex) => (
                <MenuItem
                  key={ansIndex}
                  value={ansIndex}
                  sx={{
                    fontSize: "20px",
                  }}
                >
                  {answer}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      ))}
      <Send
        modalData={props.modalData}
        modalOpen={props.modalOpen}
        setModalOpen={props.setModalOpen}
      />
      <Answer isAnswerOpen={props.isAnswerOpen} answerData={props.answerData} />
    </Box>
  );
};
