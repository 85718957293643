export const menuItemsForSchools = [
  { value: "Средняя школа № 1" },
  { value: "Средняя школа № 3" },
  { value: "Средняя школа № 6" },
  { value: "Средняя школа № 8" },
  { value: "Средняя школа № 9" },
  { value: "Средняя школа № 13" },
  { value: "Средняя школа № 14" },
  { value: "Средняя школа № 16" },
  { value: "Средняя школа № 17" },
  { value: "Средняя школа № 20" },
  { value: "Средняя школа № 21" },
  { value: "Средняя школа № 23" },
  { value: "Средняя школа № 24" },
  { value: "Средняя школа № 27" },
  { value: "Средняя школа № 28" },
  { value: "Средняя школа № 29" },
  { value: "Средняя школа № 30" },
  { value: "Средняя школа № 31" },
  { value: "Средняя школа № 32" },
  { value: "Средняя школа № 33" },
  { value: "Средняя школа № 36" },
  { value: "Средняя школа № 37" },
  { value: "Средняя школа № 38" },
  { value: "Средняя школа № 39" },
  { value: "Средняя школа № 40" },
  { value: "Средняя школа № 41" },
  { value: "Средняя школа № 42" },
  { value: "Средняя школа № 43" },
  { value: "Средняя школа № 45" },
  { value: "Лицей № 3" },
  { value: "Гимназия № 1" },
  { value: "Гимназия № 4" },
  { value: "Гимназия № 5" },
  { value: "Гимназия № 7" },
  { value: "Гимназия № 11" },
  { value: "Гимназия № 48" },
];

export const menuItemsForClasses = [
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
  { value: 11 },
];
