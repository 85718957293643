import { Box, Typography } from "@mui/material";

export const NormalMain = (props) => {
  return (
    <Box sx={{ height: "100%", textAlign: "start" }}>
      <Typography
        sx={{
          fontSize: 20,
          color: "rgba(27, 55, 54, 1)",
          textIndent: "5%",
          display: { xs: "none", sm: "block", xl: "block" },
        }}
      >
        Приветствую, {props.data} ! Так тебя зовут в нашем мире. Твой путь
        начинается здесь - на краю миров, где каждое слово и каждый выбор решит
        твою судьбу. Сейчас ты белый лист, но впереди тебя ждут хитроумные
        загадки, что определят кем тебе быть: воином, магом, плутом, следопытом
        или бардом. <br />
        <span style={{ display: "block" }}>
          Для перехода между вопросами используй флажки с стрелочками внизу. В
          меню слева ты найдешь список вопросов, используй его, чтобы быстро
          переключаться между ними. Справа ты найдешь таблицу рейтинга. Так же в
          конце ты можешь испытать свои силы в хард-режиме. <br />
        </span>
        <span style={{ display: "block" }}>Желаем удачи!</span>
      </Typography>
      <Typography
        sx={{
          fontSize: 20,
          color: "rgba(27, 55, 54, 1)",
          textIndent: "5%",
          display: { xs: "block", sm: "none", xl: "none" },
        }}
      >
        Приветствую, {props.data}! Так тебя зовут в нашем мире. Твой путь
        начинается здесь - на краю миров, где каждое слово и каждый выбор решит
        твою судьбу. Сейчас ты белый лист, но впереди тебя ждут хитроумные
        загадки, что определят кем тебе быть: воином, магом, плутом, следопытом
        или бардом. <br />
        <span style={{ display: "block" }}>
          Для перехода между вопросами используй флажки с стрелочками внизу. В
          меню сверху ты найдешь список вопросов, используй его, чтобы быстро
          переключаться между ними. Снизу ты найдешь таблицу рейтинга. Так же в
          конце ты можешь испытать свои силы в хард-режиме. <br />
        </span>
        <span style={{ display: "block" }}>Желаем удачи!</span>
      </Typography>
    </Box>
  );
};
