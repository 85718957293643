import { Box, Typography } from "@mui/material";

export const Answer = (props) => {
  return (
    <Box
      sx={{
        display: props.isAnswerOpen ? "flex" : "none",
        flexDirection: "column",
        textAlign: "start",
      }}
    >
      <Typography
        sx={{
          color: "rgba(27, 55, 54, 1)",
          fontSize: 30,
          textTransform: "uppercase",
          fontWeight: "bold",
          border: 0,
          borderTop: 2,
          borderStyle: "dashed",
          borderColor: "rgba(148, 129, 121, 1)",
          pb: 2,
          mb: 2,
          pt: 2,
          width: "100%",
        }}
      >
        Правильный ответ:
      </Typography>
      <Typography sx={{ fontSize: "16px", color: "rgba(27, 55, 54, 1)" }}>
        {props.answerData}
      </Typography>
    </Box>
  );
};
