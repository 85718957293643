import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import "@fontsource/roboto/300.css";
import { AuthForm } from "./components/auth";
import { useCookies } from "react-cookie";
import * as React from "react";
import MainComponent from "./components/mainComponent";
import "./App.css";
import axios from "axios";
import Admin from "./components/admin";
import Header from "./components/header";
import Footer from "./components/footer";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import fon from "./images/fon.png";
import mobFon from "./images/MobileFon.png";
import { RegistrationForm } from "./components/regist";

function App() {
  const [cookies] = useCookies(["aboba"]);
  const [isAuth, setIsAuth] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isHard, setIsHard] = React.useState(false);

  const Layout = () => {
    return (
      <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            justifyContent: "space-between",
            backgroundImage: isMobile ? `url(${mobFon})` : `url(${fon})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            flexGrow: 1,
          }}
        >
          {!isMobile && <Header isAuth={isAuth} />}
          <Outlet />
        </Box>
        <Footer />
      </Box>
    );
  };
  // Проверка на наличие cookie для аутентификации
  React.useEffect(() => {
    if (cookies.aboba === undefined) {
      setIsAuth(false);
    } else {
      setIsAuth(true);
    }
  }, [cookies]);

  const routes = isAuth
    ? [
        {
          path: "/",
          element: (
            <MainComponent
              isAuth={isAuth}
              isHard={isHard}
              setIsHard={setIsHard}
            />
          ),
        },
        {
          path: "/*",
          element: (
            <MainComponent
              isAuth={isAuth}
              isHard={isHard}
              setIsHard={setIsHard}
            />
          ),
        },
        {
          path: "adminSuperSecretPanel",
          element: <Admin />,
        },
      ]
    : [
        {
          path: "/",
          element: <AuthForm />,
        },
        {
          path: "/*",
          element: <AuthForm />,
        },
        {
          path: "registration",
          element: <RegistrationForm />,
        },
      ];

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: routes,
    },
  ]);

  return (
    <div id="main">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
