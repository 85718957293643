import {
  Box,
  Container,
  FormControl,
  InputAdornment,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Link,
} from "@mui/material";
import { firstPartOfRegistTextFields } from "../static/textFields";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import { menuItemsForClasses, menuItemsForSchools } from "../static/menuItems";
import logo from "../images/logoWeekend.png";
import styled from "styled-components";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";

const GrTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(198, 186, 171, 1)",
      borderRadius: 15,
      borderWidth: 2,
    },
    "&:hover fieldset": {
      borderColor: "rgba(158, 158, 116, 1)",
      borderRadius: 15,
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(198, 186, 171, 1)",
      borderRadius: 15,
    },
    "& input": {
      backgroundColor: "rgba(255, 253, 251, 1)",
      borderRadius: 15,
      margin: 7,
      fontSize: 18,
    },
  },
  "& label": {
    display: "flex",
    height: "40%",
    alignItems: "flex-end",
    alignSelf: "self-start",
    justifyContent: "center",
    "&.MuiInputLabel-shrink": {
      transform: "translate(30%, -50%) scale(0.75)",
      color: "white",
    },
  },
});

export const RegistrationForm = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    school: "",
    class: "",
    password: "",
    isAgree: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleDateChange = (date) => {
    if (date) {
      setFormData({ ...formData, bd: date.format("YYYY-MM-DD") });
    } else {
      setFormData({ ...formData, bd: "" });
    }
  };

  // Обработчик изменения значений в полях
  const handleChange = (key, value) => {
    setFormData((prevData) => ({ ...prevData, [key]: value }));
  };

  // Проверка на заполненность всех обязательных полей
  const isFormValid =
    formData.name &&
    formData.email &&
    formData.bd &&
    formData.phone &&
    formData.school &&
    formData.class &&
    formData.password &&
    formData.isAgree;

  // Обработчик для показа/скрытия пароля
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const regist = async () => {
    try {
      await axios.post("/api/auth/register", {
        email: formData.email,
        password: formData.password,
        fio: formData.name,
        birthday: formData.bd,
        schoolname: formData.school,
        schoolclass: formData.class,
        number: formData.phone,
      });
      const bodyFormData = new FormData();
      bodyFormData.append("username", formData.email);
      bodyFormData.append("password", formData.password);
      await axios.post("/api/auth/jwt/login", bodyFormData);
      await axios.post("/api/top");
    } catch {
      alert("Ошибка, которой быть не должно. Попробуйте ввести другую почту");
    }
  };

  return (
    <Container
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        width: { xs: "100%", sm: "75%", md: "60%", xl: "35%" },
        padding: { xs: 3, sm: 1, md: 4 },
      }}
    >
      <img
        src={logo}
        alt="Лого куба"
        style={{
          maxWidth: "140px",
          height: "auto",
          alignSelf: "center",
        }}
      />
      <FormControl
        /*onSubmit={handleSubmit}*/
        sx={{
          mt: 4,
          display: "flex",
          alignItems: "center",
          flexDirection: { xs: "column-reverse", sm: "row" },
          justifyContent: "space-evenly",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "90%",
            mt: { xs: 2, sm: 0 },
          }}
        >
          {firstPartOfRegistTextFields.map((tf, index) => (
            <GrTextField
              value={formData[tf.name]}
              onChange={(e) => handleChange(tf.name, e.target.value)}
              label={tf.label}
            />
          ))}
          <TextField
            label="Номер телефона"
            value={formData.phone}
            onChange={(e) => handleChange("phone", e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(198, 186, 171, 1)",
                  borderRadius: "15px",
                  borderWidth: 2,
                },
                "&:hover fieldset": {
                  borderColor: "rgba(158, 158, 116, 1)",
                  borderRadius: "15px",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgba(198, 186, 171, 1)",
                  borderRadius: "15px",
                },
                "& input": {
                  backgroundColor: "rgba(255, 253, 251, 1)",
                  borderRadius: "15px",
                  margin: 1,
                  fontSize: 18,
                },
              },
              "& label": {
                display: "flex",
                height: "40%",
                alignItems: "flex-end",
                alignSelf: "self-start",
                justifyContent: "center",
                "&.MuiInputLabel-shrink": {
                  transform: "translate(10%, -50%) scale(0.75)",
                  color: "white",
                },
              },
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "rgba(198, 186, 171, 1)",
                    borderRadius: "15px",
                    borderWidth: 2,
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(158, 158, 116, 1)",
                    borderRadius: "15px",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(198, 186, 171, 1)",
                    borderRadius: "15px",
                  },
                  "& input": {
                    backgroundColor: "rgba(255, 253, 251, 1)",
                    borderRadius: "15px",
                    margin: 1,
                    fontSize: 18,
                  },
                },
                "& label": {
                  display: "flex",
                  height: "40%",
                  alignItems: "flex-end",
                  alignSelf: "self-start",
                  justifyContent: "center",
                  "&.MuiInputLabel-shrink": {
                    transform: "translate(20%, -50%) scale(0.75)",
                    color: "white",
                  },
                },
              }}
              label="Дата рождения"
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  InputLabelProps: {
                    shrink: true,
                  },
                },
              }}
            />
          </LocalizationProvider>

          <TextField
            select
            label="Школа"
            value={formData.school}
            onChange={(e) => handleChange("school", e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(198, 186, 171, 1)",
                  borderRadius: "15px",
                  borderWidth: 2,
                },
                "&:hover fieldset": {
                  borderColor: "rgba(158, 158, 116, 1)",
                  borderRadius: "15px",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgba(198, 186, 171, 1)",
                  borderRadius: "15px",
                },
              },
              "& .MuiSelect-select": {
                backgroundColor: "rgba(255, 253, 251, 1)",
                color: "black", // Цвет текста выбранного элемента
                borderRadius: "15px",
                m: 1,
                fontSize: 18,
              },
              "& label": {
                display: "flex",
                height: "40%",
                alignItems: "flex-end",
                alignSelf: "self-start",
                justifyContent: "center",
                color: "rgba(0, 0, 0, 0.6)",
                fontSize: 16,
                "&.MuiInputLabel-shrink": {
                  transform: "translate(30%, -50%) scale(0.75)",
                  color: "white",
                },
              },
            }}
          >
            {menuItemsForSchools.map((school, index) => (
              <MenuItem key={index} value={school.value}>
                {school.value}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="Класс"
            value={formData.class}
            onChange={(e) => handleChange("class", e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(198, 186, 171, 1)",
                  borderRadius: "15px",
                  borderWidth: 2,
                },
                "&:hover fieldset": {
                  borderColor: "rgba(158, 158, 116, 1)",
                  borderRadius: "15px",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgba(198, 186, 171, 1)",
                  borderRadius: "15px",
                },
              },
              "& .MuiSelect-select": {
                backgroundColor: "rgba(255, 253, 251, 1)",
                color: "black", // Цвет текста выбранного элемента
                borderRadius: "15px",
                m: 1,
                fontSize: 18,
              },
              "& label": {
                display: "flex",
                height: "40%",
                alignItems: "flex-end",
                alignSelf: "self-start",
                justifyContent: "center",
                color: "rgba(0, 0, 0, 0.6)",
                fontSize: 16,
                "&.MuiInputLabel-shrink": {
                  transform: "translate(30%, -50%) scale(0.75)",
                  color: "white",
                },
              },
            }}
          >
            {menuItemsForClasses.map((clas, index) => (
              <MenuItem key={index} value={clas.value}>
                {clas.value}
              </MenuItem>
            ))}
          </TextField>
          <GrTextField
            label="Пароль"
            fullWidth
            value={formData.password}
            onChange={(e) => handleChange("password", e.target.value)}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? (
                      <VisibilityOff sx={{ color: "rgba(255, 253, 251, 1)" }} />
                    ) : (
                      <Visibility sx={{ color: "rgba(255, 253, 251, 1)" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </FormControl>
      <FormControlLabel
        sx={{ mt: 2, alignSelf: "center" }}
        control={
          <Checkbox
            sx={{
              color: "rgba(198, 186, 171, 1)",
              "&.Mui-checked": {
                color: "rgba(38, 121, 137, 1)", // Цвет галочки и границы
              },
            }}
            checked={formData.isAgree}
            onChange={(e) => handleChange("isAgree", e.target.checked)}
          />
        }
        label={
          <Link
            sx={{ color: "rgba(38, 121, 137, 1)" }}
            href="https://disk.yandex.ru/i/R-F-Fe1m6lJYvg"
            underline="none"
          >
            Даю согласие на регистрацию в АИС Красноярского края "Навигатор
            дополнительного образования Красноярского края”
          </Link>
        }
      />
      <Button
        fullWidth
        disabled={!isFormValid}
        onClick={() => regist()}
        variant="contained"
        sx={{
          mt: 2,
          padding: 1.5,
          fontSize: 18,
          borderRadius: "15px",
          backgroundColor: "rgba(38, 121, 137, 1)",
        }}
      >
        {" "}
        Зарегистрироваться
      </Button>
      <Link
        color="secondary"
        href="/"
        underline="none"
        sx={{
          color: "rgba(42, 83, 81, 1)",
          fontSize: 20,
          textAlign: "center",
          mt: 2,
        }}
      >
        {" "}
        <b> Войти</b>
      </Link>
    </Container>
  );
};
